import React,{useState,useEffect,useContext} from 'react';
import { MyContext } from '../contexts/MyContext';
import { FcNightLandscape, FcLandscape, FcPrivacy, FcOrganization ,FcVoicePresentation} from "react-icons/fc";
import { AiOutlineUser, AiOutlineArrowUp } from "react-icons/ai";
import {Link } from "react-router-dom"; 
import './sticky.css';

const Sticky = () => {
       const {isthemes} = useContext(MyContext);
       const [darkMode, setDarkMode] = useState(false);
       const ActiveMode = async () => {
              setDarkMode(!darkMode);
              if(darkMode === true){ 
                     document.body.classList.add('light__mode');
                     document.body.classList.remove('dark__mode');
                      localStorage.setItem('Theme','light__mode'); 
                     await isthemes();
                     return;
              }
              if(darkMode === false){ 
                     document.body.classList.add('dark__mode');
                     document.body.classList.remove('light__mode');
                      localStorage.setItem('Theme','dark__mode'); 
                     await isthemes();
                     return;
              }
       };
       useEffect(async () => {
              if (localStorage.getItem('Theme') === 'light__mode') {
                     document.body.classList.add('light__mode');
              } else if (localStorage.getItem('Theme') === 'dark__mode') {
                     document.body.classList.add('dark__mode');
                     
                     //setDarkMode(!darkMode);
              }
       }, [darkMode]);

     
            const scrollToTop =()=> {
              window.scrollTo({
                top: 0,
                behavior: "smooth"
              });
            }
            
         
       return ( <>
              <div className="so__groups__sticky " >
                    
                            {localStorage.getItem('Theme') === 'dark__mode' ? <>
                                   <div className="switch-checkbox" onClick={ActiveMode}>
                                          <FcNightLandscape  /> 
                                          <span> Turn Off Dark Mode </span>
                                   </div>
                            </> :<>
                                   <div className="switch-checkbox" onClick={ActiveMode}>
                                          <FcLandscape /> 
                                          <span> Turn On Dark Mode </span>
                                   </div>
                            </> }
                            
                     
                     
                     <div className="switch-checkbox">
                            <Link to ={`Register`}>
                                   <FcPrivacy />
                                   <span> Login / Register </span>
                           </Link>
                     </div>
                     <div className="switch-checkbox">
                            <Link to ={`ContactPage`}>
                                   <FcVoicePresentation />
                                   <span> Contact </span>
                           </Link>
                     </div>
                     <div className="switch-checkbox" onClick={scrollToTop}>
                            <AiOutlineArrowUp />
                           <span> Back To Top </span>
                     </div>

              </div>

       </> )
}

export default Sticky
