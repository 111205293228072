import React, { Suspense, lazy } from 'react';
import {BrowserRouter as Router,Redirect,Switch,Route} from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import '../App.css';
import Sticky from './Sticky';
const Home = lazy(() => import('./Home'));
const NotFound = lazy(() => import("./NotFound"));
const Demo = lazy(() => import("./Demo"));
const Login = lazy(() => import("./LoginPage/Login"));
const Register = lazy(() => import("./LoginPage/Register"));
const RegisterVerification = lazy(() => import("./LoginPage/RegisterVerification"));
const EmailVerification = lazy(() => import("./LoginPage/EmailVerification"));
const CandidateAll = lazy(() => import("./Candidate/CandidateAll"));
const CandidateProfileads = lazy(() => import("./Candidate/CandidateProfileads"));
const CandidateResume = lazy(() => import("./Candidate/CandidateResume"));
const CandidateProfile = lazy(() => import("./Candidate/CandidateProfile"));
const ShortlistJobs = lazy(() => import("./Candidate/ShortlistJobs"));
const MyApplied = lazy(() => import("./Candidate/MyApplied"));
const ForgetPassword = lazy(() => import("./LoginPage/ForgetPassword"));
const ChangePassword = lazy(() => import("./LoginPage/ChangePassword"));
const Logout = lazy(() => import("./LoginPage/Logout"));
const ContactPage = lazy(() => import('./ContactPage'));
const Books = lazy(() => import('./Books'));
const BooksPreview = lazy(() => import('./BooksPreview'));

const EmployerAll = lazy(() => import("./Employer/EmployerAll"));
const AddJob = lazy(() => import("./Employer/AddJob"));
const AddProfile = lazy(() => import("./Employer/AddProfile"));
const JobManager = lazy(() => import("./Employer/JobManager"));
const EditJobs = lazy(() => import("./Employer/EditJobs"));
const MyApplicants = lazy(() => import("./Employer/MyApplicants"));
const ShortlistCandidate = lazy(() => import("./Employer/ShortlistCandidate"));
const Package = lazy(() => import("./Payments/Package"));
const PackageUpgrade = lazy(() => import("./Payments/PackageUpgrade"));
const Success = lazy(() => import("./Payments/Success"));
const SuccessHire = lazy(() => import("./Payments/SuccessHire"));
const SuccessCandidate = lazy(() => import("./Payments/SuccessCandidate"));
const SuccessFreeLancer = lazy(() => import("./Payments/SuccessFreeLancer"));
const Cancel = lazy(() => import("./Payments/Cancel"));
const CancelHire = lazy(() => import("./Payments/CancelHire"));
const Successbooks = lazy(() => import("./Payments/Successbooks"));
const Cancelbooks = lazy(() => import("./Payments/Cancelbooks"));
const CancelCandidate = lazy(() => import("./Payments/CancelCandidate"));
const CancelFreeLancer = lazy(() => import("./Payments/CancelFreeLancer"));
const FeaturedPlanCandidate = lazy(() => import("./Payments/FeaturedPlanCandidate"));
const FeaturedPlanFreeLancer = lazy(() => import("./Payments/FeaturedPlanFreeLancer"));
const BookPayments = lazy(() => import("./Payments/BookPayments"));
const PackHire = lazy(() => import("./Payments/PackHire"));
const JobsViews = lazy(() => import("./JobsViews"));
const Categories = lazy(() => import("./Categories"));
const FeaturedJobs = lazy(() => import("./FeaturedJobs"));
const EmployersProfile = lazy(() => import("./EmployersProfile"));
const AllJobs = lazy(() => import("./AllJobs"));
const EmployersList = lazy(() => import("./EmployersList"));
const CandidatesList = lazy(() => import("./CandidatesList"));
const FreelancerList = lazy(() => import("./FreelancerList"));
const HireFreelancerAll = lazy(() => import("./HireFreelancer/HireFreelancerAll"));
const HireFreelancerProfileads = lazy(() => import("./HireFreelancer/HireFreelancerProfileads"));
const HireFreelancerOrder = lazy(() => import("./HireFreelancer/HireFreelancerOrder"));
const HireFreelancerJob = lazy(() => import("./HireFreelancer/HireFreelancerJob"));
const HireJobs = lazy(() => import("./HireFreelancer/HireJobs"));
const EditHireJobs = lazy(() => import("./HireFreelancer/EditHireJobs"));
const HireFreelancerJobsList = lazy(() => import("./HireFreelancer/HireFreelancerJobsList"));
const FLApplyList = lazy(() => import("./HireFreelancer/FLApplyList"));
const HireFreelancerProfile = lazy(() => import("./HireFreelancer/HireFreelancerProfile"));
const FreelancerAll = lazy(() => import("./Freelancer/FreelancerAll"));
const FreelancerProfileads = lazy(() => import("./Freelancer/FreelancerProfileads"));
const FreelancersProfile = lazy(() => import("./Freelancer/FreelancersProfile"));
const FreeLancerApplyedJobs = lazy(() => import("./Freelancer/FreeLancerApplyedJobs"));
const FLNotifications = lazy(() => import("./Freelancer/FLNotifications"));
const AuthorAll = lazy(() => import("./Author/AuthorAll"));
const AuthorProfileads = lazy(() => import("./Author/AuthorProfileads"));
const AddBooks = lazy(() => import("./Author/AddBooks"));
const BooksNotifications = lazy(() => import("./Author/BooksNotifications"));
const BooksListAdmin = lazy(() => import("./Author/BooksListAdmin"));
const EditBooks = lazy(() => import("./Author/EditBooks"));
const Authors = lazy(() => import("./Author/Authors"));
const AuthorProfile = lazy(() => import("./Author/AuthorProfile"));
const SearchPage = lazy(() => import("./search/SearchPage"));
const PrivacyPolicy = lazy(() => import('./PrivacyPolicy'));
const TermsofUse = lazy(() => import('./TermsofUse'));
const RefundPolicy = lazy(() => import('./RefundPolicy'));


function Dashboard() {
  return (<>   
    <Router basename="/">
      <Suspense fallback={<div className="preloader-area" ><div className="spinner"><div className="inner">
        <div className="disc"></div><div className="disc"></div><div className="disc"></div></div></div>
        <div className="spinner2"><p> Loading...</p></div></div>}>
       
        <Switch>
         <Route exact path="/" component={Home}/>
         
         <Route exact path="/Login" component={Login}/>
         <Route exact path="/Register" component={Register}/>
         <Route exact path="/RegisterVerification" component={RegisterVerification}/>
         <Route exact path="/EmailVerification/:passkey" component={EmailVerification}/>
         <Route exact path="/ForgetPassword" component={ForgetPassword}/>
         <Route exact path="/ChangePassword/:passkey" component={ChangePassword}/>
         <Route exact path="/Logout" component={Logout}/>
         <Route exact path="/ContactPage" component={ContactPage}/>
         <Route exact path="/Books" component={Books}/>
         <Route exact path="/BooksPreview/:AddPreview" component={BooksPreview}/>
         <Route exact path="/EditBooks/:AddPreview" component={EditBooks}/>
         <Route exact path="/EmployersProfile/:AddPreview" component={EmployersProfile}/>
         <Route exact path="/BooksListAdmin" component={BooksListAdmin}/>
         <Route exact path="/SearchPage/:AddPreview" component={SearchPage}/>
         
         <Route exact path="/CandidateAll" component={CandidateAll}/>
         <Route exact path="/EmployerAll" component={EmployerAll}/>
         <Route exact path="/AddJob" component={AddJob}/>
         <Route exact path="/AddProfile" component={AddProfile}/>
         <Route exact path="/CandidateProfileads" component={CandidateProfileads}/>
         <Route exact path="/CandidateResume" component={CandidateResume}/>
         <Route exact path="/MyApplied" component={MyApplied}/>
         <Route exact path="/MyApplicants" component={MyApplicants}/>
         <Route exact path="/ShortlistJobs" component={ShortlistJobs}/>
         <Route exact path="/ShortlistCandidate" component={ShortlistCandidate}/>
         <Route exact path="/CandidateProfile/:AddPreview" component={CandidateProfile}/>
         <Route exact path="/JobManager" component={JobManager}/>
         <Route exact path="/JobsViews/:AddPreview" component={JobsViews}/>
         <Route exact path="/EditJobs/:AddPreview" component={EditJobs}/>
         <Route exact path="/Package/:AddPreview" component={Package}/>
         <Route exact path="/FeaturedPlanCandidate/:AddPreview" component={FeaturedPlanCandidate}/>
         <Route exact path="/PackageUpgrade/:AddPreview/:plans" component={PackageUpgrade}/>
         <Route exact path="/Success/:transaction/:Amount/:Status" component={Success}/>
         <Route exact path="/SuccessHire/:transaction/:Amount/:Status" component={SuccessHire}/>
         <Route exact path="/Successbooks/:transaction/:Amount/:Status" component={Successbooks}/>
         <Route exact path="/SuccessCandidate/:transaction/:Amount/:Status" component={SuccessCandidate}/>
         <Route exact path="/SuccessFreeLancer/:transaction/:Amount/:Status" component={SuccessFreeLancer}/>
         <Route exact path="/CancelBook" component={Cancelbooks}/>
         <Route exact path="/Cancel" component={Cancel}/>
         <Route exact path="/CancelHire" component={CancelHire}/>
         <Route exact path="/CancelCandidate" component={CancelCandidate}/>
         <Route exact path="/CancelFreeLancer" component={CancelFreeLancer}/>
         <Route exact path="/Categories/:AddPreview" component={Categories}/>
         <Route exact path="/FeaturedJobs" component={FeaturedJobs}/>
         <Route exact path="/AllJobs" component={AllJobs}/>
         <Route exact path="/EmployersList" component={EmployersList}/>
         <Route exact path="/CandidatesList" component={CandidatesList}/>
         <Route exact path="/FreelancerList" component={FreelancerList}/>
         <Route exact path="/HireFreelancerAll" component={HireFreelancerAll}/>
         <Route exact path="/HireFreelancerProfileads" component={HireFreelancerProfileads}/>
         <Route exact path="/HireFreelancerOrder" component={HireFreelancerOrder}/>
         <Route exact path="/HireFreelancerJob/:AddPreview" component={HireFreelancerJob}/>
         <Route exact path="/HireJobs" component={HireJobs}/>
         <Route exact path="/EditHireJobs/:AddPreview" component={EditHireJobs}/>
         <Route exact path="/FreelancerAll" component={FreelancerAll}/>
         <Route exact path="/FreelancerProfileads" component={FreelancerProfileads}/>
         <Route exact path="/AuthorAll" component={AuthorAll}/>
         <Route exact path="/HireFreelancerJobsList" component={HireFreelancerJobsList}/>
         <Route exact path="/FLApplyList/:AddPreview" component={FLApplyList}/>
         <Route exact path="/FreelancersProfile/:AddPreview" component={FreelancersProfile}/>
         <Route exact path="/FreeLancerApplyedJobs" component={FreeLancerApplyedJobs}/>
         <Route exact path="/FLNotifications" component={FLNotifications}/>
         <Route exact path="/FeaturedPlanFreeLancer/:AddPreview" component={FeaturedPlanFreeLancer}/>
         <Route exact path="/PackHire/:AddPreview" component={PackHire}/>
         <Route exact path="/HireFreelancerProfile/:AddPreview" component={HireFreelancerProfile}/>
         <Route exact path="/BookPayments/:AddPreview" component={BookPayments}/>
         <Route exact path="/AuthorProfileads" component={AuthorProfileads}/>
         <Route exact path="/AddBooks" component={AddBooks}/>
         <Route exact path="/BooksNotifications" component={BooksNotifications}/>
         <Route exact path="/Authors" component={Authors}/>
         <Route exact path="/AuthorProfile/:AddPreview" component={AuthorProfile}/>

         <Route path="/Privacy-Policy" component={PrivacyPolicy} />
          <Route path="/Terms-Of-Service" component={TermsofUse} />
          <Route path="/Cancellation-Refund" component={RefundPolicy} />

          
         <Route exact path="/404" component={NotFound} />
         <Route exact path="/Demo" component={Demo} />
          <Redirect to="/404" />

       
        </Switch>
      </Suspense>
      <Sticky />
    </Router>
    
    </>
  );
}
export default Dashboard;
