
//export const WEB_URL_UPLOAD = "http://localhost/bookbubworld/uploads/";
//export const WEB_URL = "http://192.168.0.129:8080/";



export const WEB_URL_UPLOAD = "https://writelf.com/uploads/";
export const WEB_URL = "https://writelf.com/";

 export const CAPTCHA_SITE_KEY = "6Le0HSwcAAAAAMyVD7stcBkHHSwGQdoL1g1B_9ZS";

 export const API_KEY_STRIPE = "pk_live_yn6RjQtCI6a8ITcRqjeSdLKv00FSgvPKl8";



