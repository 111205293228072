import React, { createContext,Component } from "react";
import axios from 'axios';
import { WEB_URL } from './Apilinks';
export const MyContext = createContext();

// Define the base URL
const Axios = axios.create({
    baseURL: WEB_URL,
});

class MyContextProvider extends Component{
  

    state = {
        isAuth:false,
        theUserid:null,
        theUsername:null,
        thejob:null,
        theverify:null,
        theTheme:null,
        theemail:null,
        thecanfeatured:null,
        thenotsstatus:null,
    }
    componentDidMount(){
        this.isLoggedIn();
    }
  
        isLoggedIn = async () => {
            let jobsID = JSON.parse(localStorage.getItem('jobsID'));
            let jobsName = JSON.parse(localStorage.getItem('jobsName'));
            let jobsuser = JSON.parse(localStorage.getItem('jobsuser'));
            let jobsemail = JSON.parse(localStorage.getItem('jobsemail'));
            let emailverify = JSON.parse(localStorage.getItem('emailverify'));
            let canfeatured = JSON.parse(localStorage.getItem('canfeatured'));
            if (localStorage.getItem('jobsID')) {
                this.setState({
                    ...this.state,
                    isAuth:true,
                    theUserid:jobsID,
                    theUsername:jobsName,
                    thejob:jobsuser,
                    theemail:jobsemail,
                    theverify:emailverify,
                    thecanfeatured:canfeatured,
                });
            }
        }
        logoutUser = () => {
            localStorage.removeItem('jobsID');
            localStorage.removeItem('jobsName');
            localStorage.removeItem('jobsuser');
            localStorage.removeItem('emailverify');
            localStorage.removeItem('jobsemail');
            localStorage.removeItem('FLApplyJobIDS');
            localStorage.removeItem('FLcontactHireIDS');
            this.setState({
                ...this.state,
                isAuth:false,
                theUserid:null,
                theUsername:null,
                thejob:null,
                theverify:null,
                theTheme:null,
                theemail:null,
                thecanfeatured:null,
                thenotsstatus:null,
            })
            
            window.location.href = '/Login';
        }
       
        isthemes = async () => {
            let Themesss = localStorage.getItem('Theme');
            if (localStorage.getItem('Theme')) {
                this.setState({
                    ...this.state,
                    theTheme:Themesss,
                });
            }
        }
        isFeaturedIn = async () => {
            let featuredsss = localStorage.getItem('featured');
            if (localStorage.getItem('featured')) {
                this.setState({
                    ...this.state,
                    thecanfeatured:featuredsss,
                });
            }
        }
        isswitchIn = async () => {
            let jobsusersss =  JSON.parse(localStorage.getItem('jobsuser'));
            if (localStorage.getItem('jobsuser')) {
                this.setState({
                    ...this.state,
                    thejob:jobsusersss,
                });
            }
        }
        isnamesIn = async () => {
            let jobsName =  JSON.parse(localStorage.getItem('jobsName'));
            if (localStorage.getItem('jobsName')) {
                this.setState({
                    ...this.state,
                    theUsername:jobsName,
                });
            }
        }
        isnotsssIn = async () => {
            let notifications =  JSON.parse(localStorage.getItem('notifications'));
            if (localStorage.getItem('notifications')) {
                this.setState({
                    ...this.state,
                    thenotsstatus:notifications,
                });
            }
        }
      
        RegisterUser = async (user,recaptcha_response) => {
            const registerqqq = await Axios.post('Register',{
                user_name:user.name,
                user_email:user.email,
                password:user.password,
                subscribe:user.subscribe, 
                jobsuser:user.jobsuser,
                recaptcha_response:recaptcha_response,
            });
           
            
            return registerqqq.data;
        }
        LoginUser = async (user) => {
            const LoginUsersss = await Axios.post('Login',{
                user_email:user.email,
                password:user.password,
            });
            return LoginUsersss.data;
        }
        emailverifyall = async (passkeyids) => {
            const emailverifyallqqq = await Axios.post('emailverifyallsss',{
                passkeyids:passkeyids,
            });
            return emailverifyallqqq.data;
        }
        ForgetMailpassword = async (user) => {
            const loginForget = await Axios.post('forgetpasswordsss',{
                email:user.useremail,
            });
            return loginForget.data;
        }
        ChangePasswordall = async (user,passkey) => {
            const ChangePasswordallsss = await Axios.post('ChangePasswordallqqq',{
                newpassword:user.password,
                passkeyids:passkey,
            });
            return ChangePasswordallsss.data;
        }
        jobsaddsssall = async (user,category,country,theUserid,qualification,jobdate,lastdateadd,newstatus) => {
            const jobsaddsssallqqq = await Axios.post('jobsaddsssallsss',{
                
                title: user.title,
                type: user.type,
                salary: user.salary,
                salbenefits: user.salbenefits,
                state: user.state,
                city: user.city,
                contactname: user.contactname,
                contactphone: user.contactphone,
                defaultemail: user.defaultemail,
                webpage: user.webpage,
                gender: user.gender,
                experience: user.experience,
                statusss: newstatus,
                currency: user.currency,
                selectedoption: user.selectedOption,
                category: category,
                country: country,
                description: user.description,
                theUserid: theUserid,
                qualification: qualification,
                jobdate: jobdate,
                lastdateadd: lastdateadd,
            });
            return jobsaddsssallqqq.data;
        }
        profilelistall = async (profileids) => {
            const profilelistallqqq = await Axios.post('profilelistallsss',{
                profileids: profileids,
            });
            return profilelistallqqq.data;
        }
        shortlistall = async (profileids) => {
            const shortlistallqqq = await Axios.post('shortlistallsss',{
                profileids: profileids,
            });
            return shortlistallqqq.data;
        }
        MyAppliedall = async (profileids) => {
            const MyAppliedallqqq = await Axios.post('MyAppliedallsss',{
                profileids: profileids,
            });
            return MyAppliedallqqq.data;
        }
        MyApplicantsall = async (profileids) => {
            const MyApplicantsallqqq = await Axios.post('MyApplicantsallsss',{
                profileids: profileids,
            });
            return MyApplicantsallqqq.data;
        }
        viewallsssall = async (ids) => {
            const viewallsssallqqq = await Axios.post('viewallsssallsss',{
                ids: ids,
            });
            return viewallsssallqqq.data;
        }
        viewjobsallsss = async (ids) => {
            const viewjobsallsssqqq = await Axios.post('viewjobsallsssaaa',{
                ids: ids,
            });
            return viewjobsallsssqqq.data;
        }
        jobseditsssall = async (user,category,country,thejobid,qualification,jobdate,lastdateadd,counts,dates,empids,editfeatured,statusss) => {
            const jobseditsssallqqq = await Axios.post('jobseditsssallsss',{
                title: user.title,
                type: user.type,
                salary: user.salary,
                salbenefits: user.salbenefits,
                state: user.state,
                city: user.city,
                contactname: user.contactname,
                contactphone: user.contactphone,
                defaultemail: user.defaultemail,
                webpage: user.webpage,
                gender: user.gender,
                experience: user.experience,
                currency: user.currency,
                selectedoption: user.selectedOption,
                category: category,
                country: country,
                description: user.description,
                thejobid: thejobid,
                qualification: qualification,
                jobdate: jobdate,
                lastdateadd: lastdateadd,
                counts: counts,
                dates: dates,
                empids: empids,
                editfeatured: editfeatured,
                statusss: statusss,
            });
            return jobseditsssallqqq.data;
        }
        publishjobssssall = async (ids,counts) => {
            const publishjobssssallqqq = await Axios.post('publishjobssssallsss',{
                ids: ids,
                counts: counts,
            });
            return publishjobssssallqqq.data;
        }
        deletesJobsall = async (ids,counts,paydeletes) => {
            const deletesJobsallqqq = await Axios.post('deletesJobsallsss',{
                ids: ids,
                counts: counts,
                paydeletes: paydeletes,
            });
            return deletesJobsallqqq.data;
        }
        deleteimagelistall = async (ids,arrayids,imgid,arraylength) => {
            const deleteimagelistallqqq = await Axios.post('deleteimagelistallsss',{
                ids: ids,
                arrayids: arrayids,
                imgid: imgid,
                arraylength: arraylength,
            });
            return deleteimagelistallqqq.data;
        }
        deleteimagelistallemp = async (ids,arrayids,imgid,arraylength) => {
            const deleteimagelistallempqqq = await Axios.post('deleteimagelistallempsss',{
                ids: ids,
                arrayids: arrayids,
                imgid: imgid,
                arraylength: arraylength,
            });
            return deleteimagelistallempqqq.data;
        }
        memberstripe = async (token,memberid,plan,price,theemail) => {
            const memberstripewww = await Axios.post('memberstripesss',{
                token:token,
                memberid:memberid,
                plan:plan,
                price:price,
                theemail:theemail,
            });
            return memberstripewww.data;
        }
        memberstripeupgrade = async (token,memberid,plan,price,theemail) => {
            const memberstripeupgradeqqq = await Axios.post('memberstripeupgradesss',{
                token:token,
                memberid:memberid,
                plan:plan,
                price:price,
                theemail:theemail,
            });
            return memberstripeupgradeqqq.data;
        }
        memberstripehire = async (token,memberid,plan,price,theemail) => {
            const memberstripehireqqq = await Axios.post('memberstripehiresss',{
                token:token,
                memberid:memberid,
                plan:plan,
                price:price,
                theemail:theemail,
            });
            return memberstripehireqqq.data;
        }
        
        memberstripebooks = async (token,memberid,plan,price,theemail) => {
            const memberstripebooksqqq = await Axios.post('memberstripebooksss',{
                token:token,
                memberid:memberid,
                plan:plan,
                price:price,
                theemail:theemail,
            });
            return memberstripebooksqqq.data;
        }
        candidatestripe = async (token,memberid,plan,price,theemail) => {
            const candidatestripeqqq = await Axios.post('candidatestripesss',{
                token:token,
                memberid:memberid,
                plan:plan,
                price:price,
                theemail:theemail,
            });
            return candidatestripeqqq.data;
        }
        freelancerstripe = async (token,memberid,plan,price,theemail) => {
            const freelancerstripeqqq = await Axios.post('freelancerstripesss',{
                token:token,
                memberid:memberid,
                plan:plan,
                price:price,
                theemail:theemail,
            });
            return freelancerstripeqqq.data;
        }
        memberstripeupgrage = async (token,memberid,plan,price,theemail) => {
            const memberstripeupgrageqqq = await Axios.post('memberstripeupgragesss',{
                token:token,
                memberid:memberid,
                plan:plan,
                price:price,
                theemail:theemail,
            });
            return memberstripeupgrageqqq.data;
        }
        extendedall = async () => {
            const extendedallqqq = await Axios.get('extendedallsss');
            return extendedallqqq.data;
        }
        
        Featuredsssall = async () => {
            const Featuredsssall = await Axios.get(`Featuredsssallsss`);
            return Featuredsssall.data;
        }
        Featuredsssallcan = async () => {
            const Featuredsssallcanqqq = await Axios.get(`Featuredsssallcansss`);
            return Featuredsssallcanqqq.data;
        }
        logosssall = async (ids) => {
            const logosssallqqq = await Axios.post('logosssallsss',{
                ids:ids
            });
            return logosssallqqq.data;
        }
        categoriesall = async (pageNumber,lastkeyids,categories) => {
            const categoriesallqqq = await Axios.get(`categoriesallsss/${pageNumber}/${lastkeyids}/${categories}`);
            return categoriesallqqq.data;
        }
        Alljobsall = async () => {
            const Alljobsallqqq= await Axios.get(`Alljobsallsss`);
            return Alljobsallqqq.data;
        }
        RemoveLogoallimg = async (ids) => {
            const RemoveLogoallimgqqq = await Axios.post('RemoveLogoallimgsss',{
                ids:ids
            });
            return RemoveLogoallimgqqq.data;
        }
        Removefreelancerallimg = async (ids) => {
            const Removefreelancerallimgqqq = await Axios.post('Removefreelancerallimgsss',{
                ids:ids
            });
            return Removefreelancerallimgqqq.data;
        }
        RemoveResumeallfile = async (ids) => {
            const RemoveResumeallfileqqq = await Axios.post('RemoveResumeallfilesss',{
                ids:ids
            });
            return RemoveResumeallfileqqq.data;
        }
        viewcandidateall = async (ids) => {
            const viewcandidateallqqq = await Axios.post('viewcandidateallsss',{
                ids:ids
            });
            return viewcandidateallqqq.data;
        }
        EmployerBookMarkall = async (loginids,candidateids,counts) => {
            const EmployerBookMarkallqqq = await Axios.post('EmployerBookMarkallsss',{
                loginids:loginids,
                candidateids:candidateids,
                counts:counts,
            });
            return EmployerBookMarkallqqq.data;
        }
        EmployerBookMarkalljob = async (loginids,employerjobids,counts) => {
            const EmployerBookMarkalljobqqq = await Axios.post('EmployerBookMarkalljobsss',{
                loginids:loginids,
                employerjobids:employerjobids,
                counts:counts,
            });
            return EmployerBookMarkalljobqqq.data;
        }
        deletejobshortlistall = async (ids,arrayids,imgid,arraylength) => {
            const deletejobshortlistallqqq = await Axios.post('deletejobshortlistallsss',{
                ids: ids,
                arrayids: arrayids,
                imgid: imgid,
                arraylength: arraylength,
            });
            return deletejobshortlistallqqq.data;
        }
        deletemyappliedall = async (ids,arrayids,imgid,arraylength) => {
            const deletemyappliedallqqq = await Axios.post('deletemyappliedallsss',{
                ids: ids,
                arrayids: arrayids,
                imgid: imgid,
                arraylength: arraylength,
            });
            return deletemyappliedallqqq.data;
        }
        viewshortlistjobsallsss = async (ids) => {
            const viewshortlistjobsallqqq = await Axios.post('viewshortlistjobsallwww',{
                ids: ids,
            });
            return viewshortlistjobsallqqq.data;
        }
        deletecandidateshortlistall = async (ids,arrayids,imgid,arraylength) => {
            const deletecandidateshortlistallqqq = await Axios.post('deletecandidateshortlistallsss',{
                ids: ids,
                arrayids: arrayids,
                imgid: imgid,
                arraylength: arraylength,
            });
            return deletecandidateshortlistallqqq.data;
        }
        deleteMyApplicantslistall = async (ids,arrayids,imgid,arraylength) => {
            const deleteMyApplicantslistallqqq = await Axios.post('deleteMyApplicantslistallsss',{
                ids: ids,
                arrayids: arrayids,
                imgid: imgid,
                arraylength: arraylength,
            });
            return deleteMyApplicantslistallqqq.data;
        }
        ApplyCandidateall = async (ids) => {
            const ApplyCandidateallqqq = await Axios.post('ApplyCandidateallsss',{
                ids: ids,
            });
            return ApplyCandidateallqqq.data;
        }
        ApplyFreelancersall = async (ids) => {
            const ApplyFreelancersallqqq = await Axios.post('ApplyFreelancersallsss',{
                ids: ids,
            });
            return ApplyFreelancersallqqq.data;
        }
        Applysssall = async (users,ids,title,jobids,empids,userapplyname) => {
            const Applysssallqqq = await Axios.post('Applysssallsss',{
                name: users.name,
                dob: users.dob,
                gender: users.gender,
                number: users.number,
                email: users.email,
                message: users.message,
                loginids: ids,
                title: title,
                jobids: jobids,
                empids: empids,
                userapplyname: userapplyname,
            });
            return Applysssallqqq.data;
        } 
        Applysssallmeetings = async (users,ids,StartdateString,titlesss,empjobids,namesss) => {
            const Applysssallmeetingsqqq = await Axios.post('Applysssallmeetingssss',{
                time: users.time,
                duration: users.duration,
                message: users.message,
                ids: ids,
                StartdateString: StartdateString,
                titlesss: titlesss,
                empjobids: empjobids,
                namesss: namesss,
            });
            return Applysssallmeetingsqqq.data;
        }
        AllEmployersList = async (pageNumber,lastkeyids) => {
            const AllEmployersListqqq= await Axios.get(`AllEmployersListsss/${pageNumber}/${lastkeyids}`);
            return AllEmployersListqqq.data;
        }
        AllCandidatesList = async (pageNumber,lastkeyids) => {
            const AllCandidatesListqqq= await Axios.get(`AllCandidatesListsss/${pageNumber}/${lastkeyids}`);
            return AllCandidatesListqqq.data;
        }
        hireviewallsssall = async (jobsids) => {
            const hireviewallsssallqqq= await Axios.post(`hireviewallsssallsss`,{
                jobsids:jobsids
            });
            return hireviewallsssallqqq.data;
        }
        HireJobsallqqq = async (userids) => {
            const HireJobsallqqqwww= await Axios.post(`HireJobsallqqqsss`,{
                userids:userids
            });
            return HireJobsallqqqwww.data;
        }
        deletehirejobimage = async (ids,arrayids,imgid,arraylength) => {
            const deletehirejobimageqqq = await Axios.post('deletehirejobimagesss',{
                ids: ids,
                arrayids: arrayids,
                imgid: imgid,
                arraylength: arraylength,
            });
            return deletehirejobimageqqq.data;
        }
        deletefilesalllist = async (ids,arrayids,imgid,arraylength) => {
            const deletefilesalllistqqq = await Axios.post('deletefilesalllistsss',{
                ids: ids,
                arrayids: arrayids,
                imgid: imgid,
                arraylength: arraylength,
            });
            return deletefilesalllistqqq.data;
        }
        deletefrrelancerimagelistall = async (ids,arrayids,imgid,arraylength) => {
            const deletefrrelancerimagelistallqqq = await Axios.post('deletefrrelancerimagelistallsss',{
                ids: ids,
                arrayids: arrayids,
                imgid: imgid,
                arraylength: arraylength,
            });
            return deletefrrelancerimagelistallqqq.data;
        }
        deletesHireJobsall = async (ids,countlength) => {
            const deletesHireJobsallqqq = await Axios.post('deletesHireJobsallsss',{
                ids: ids,
                countlength: countlength,
            });
            return deletesHireJobsallqqq.data;
        }
        Allhirejobsall = async () => {
            const Allhirejobsallqqq= await Axios.get(`Allhirejobsallsss`);
            return Allhirejobsallqqq.data;
        }
        ApplysssallHire = async (users,ids,title,jobids,empids,lengthcount,usernamesss,contenttypesss,applycountsss) => {
            const ApplysssallHireqqq = await Axios.post('ApplysssallHiresss',{
                name: users.name,
                charge: users.charge,
                chargecurrency: users.chargecurrency,
                message: users.message,
                days:  users.days,
                weeks:  users.weeks,
                months:  users.months,
                jobdays:  users.jobdays,
                loginids: ids,
                title: title,
                jobids: jobids,
                empids: empids,
                lengthcount: lengthcount,
                usernamesss: usernamesss,
                contenttypesss: contenttypesss,
                applycountsss: applycountsss,
            });
            return ApplysssallHireqqq.data;
        } 
        ApplysssallHireContact = async (users,ids,jobids,lengthcount,timelinecount,titlesss) => {
            const ApplysssallHireContactqqq = await Axios.post('ApplysssallHireContactsss',{
                message: users.message,
                loginids: ids,
                jobids: jobids,
                lengthcount: lengthcount,
                timelinecount: timelinecount,
                titlesss: titlesss,
            });
            return ApplysssallHireContactqqq.data;
        }
        ApplysssallusersContact = async (users,ids,jobids,lengthcount,timelinecount,usernames,titlesss,loginids) => {
            const ApplysssallusersContactqqq = await Axios.post('ApplysssallusersContactsss',{
                message: users.message,
                empids: ids,
                jobids: jobids,
                lengthcount: lengthcount,
                timelinecount: timelinecount,
                usernames: usernames,
                titlesss: titlesss,
                loginids: loginids,
            });
            return ApplysssallusersContactqqq.data;
        } 
        Allapplyonesall = async (ids) => {
            const Allapplyonesallqqq = await Axios.post('Allapplyonesallsss',{
                ids: ids,
            });
            return Allapplyonesallqqq.data;
        } 
        InviteApplyallinvite = async (names,userids,applylistallcount,applylistalltimelinelength,pagetitle,empjobusername,jobids) => {
            const InviteApplyallinviteqqq = await Axios.post('InviteApplyallinvitesss',{
                names: names,
                userids: userids,
                jobids: jobids,
                applylistallcount: applylistallcount,
                applylistalltimelinelength: applylistalltimelinelength,
                pagetitle: pagetitle,
                empjobusername: empjobusername,
            });
            return InviteApplyallinviteqqq.data;
        }
        CancelApplyallinvite = async (names,userids,applylistallcount,applylistalltimelinelength,pagetitle,empjobusername,jobids) => {
            const CancelApplyallinviteqqq = await Axios.post('CancelApplyallinvitesss',{
                names: names,
                userids: userids,
                jobids: jobids,
                applylistallcount: applylistallcount,
                applylistalltimelinelength: applylistalltimelinelength,
                pagetitle: pagetitle,
                empjobusername: empjobusername,
            });
            return CancelApplyallinviteqqq.data;
        } 
        hireviewallempall = async (empids) => {
            const hireviewallempallqqq = await Axios.post('hireviewallempallsss',{
                empids: empids,
            });
            return hireviewallempallqqq.data;
        } 
        viewnotificationall = async (ids) => {
            const viewnotificationallqqq = await Axios.post('viewnotificationallsss',{
                ids: ids,
            });
            return viewnotificationallqqq.data;
        } 
        Acceptjobsallaccept = async (flnames,fluserids,applylistallcount,jobtitle,applylistalltimelinelength,hireusernames,jobids) => {
            const Acceptjobsallacceptqqq = await Axios.post('Acceptjobsallacceptsss',{
                flnames: flnames,
                fluserids: fluserids,
                applylistallcount: applylistallcount,
                jobtitle: jobtitle,
                applylistalltimelinelength: applylistalltimelinelength,
                hireusernames: hireusernames,
                jobids: jobids,
                
            });
            return Acceptjobsallacceptqqq.data;
        } 
        CancelAcceptjobsallcancel = async (flnames,fluserids,applylistallcount,jobtitle,applylistalltimelinelength,hireusernames,jobids) => {
            const CancelAcceptjobsallcancelqqq = await Axios.post('CancelAcceptjobsallcancelsss',{
                flnames: flnames,
                fluserids: fluserids,
                applylistallcount: applylistallcount,
                jobtitle: jobtitle,
                applylistalltimelinelength: applylistalltimelinelength,
                hireusernames: hireusernames,
                jobids: jobids,
                
            });
            return CancelAcceptjobsallcancelqqq.data;
        } 
        RatingAds = async (counts,ids,jobidsss,addnumbers) => {
            const RatingAdsqqq = await Axios.post('RatingAdsss',{
                counts: counts,
                ids: ids,
                jobidsss: jobidsss,
                addnumbers: addnumbers,
            });
            return RatingAdsqqq.data;
        } 
        bookslistall = async (ids) => {
            const bookslistallqqq = await Axios.post('bookslistallsss',{
                ids: ids,
            });
            return bookslistallqqq.data;
        }
        booksnotificationall = async () => {
            const booksnotificationallqqq = await Axios.get('booksnotificationallsss');
            return booksnotificationallqqq.data;
        } 
        Booksalllist = async (ids) => {
            const Booksalllistqqq = await Axios.post('Booksalllistsss',{
                ids: ids,
            });
            return Booksalllistqqq.data;
        } 
        Booksalllist = async (ids) => {
            const Booksalllistqqq = await Axios.post('Booksalllistsss',{
                ids: ids,
            });
            return Booksalllistqqq.data;
        } 
        switchall = async (loginids,switchs) => {
            const switchallqqq = await Axios.post('switchallsss',{
                loginids: loginids,
                switchs: switchs,
            });
            return switchallqqq.data;
        } 
        updateprofilesall = async (loginids) => {
            const updateprofilesall = await Axios.post('updateprofilesallsss',{
                loginids: loginids,
            });
            return updateprofilesall.data;
        } 
        updateprofilesallemp = async (loginids) => {
            const updateprofilesallempqqq = await Axios.post('updateprofilesallempsss',{
                loginids: loginids,
            });
            return updateprofilesallempqqq.data;
        } 
        updateprofilesallcan = async (loginids) => {
            const updateprofilesallcanqqq = await Axios.post('updateprofilesallcansss',{
                loginids: loginids,
            });
            return updateprofilesallcanqqq.data;
        } 
        updateprofilesallFL = async (loginids) => {
            const updateprofilesallFLqqq = await Axios.post('updateprofilesallFLsss',{
                loginids: loginids,
            });
            return updateprofilesallFLqqq.data;
        } 
        seachlistall = async (pageNumber,type,locations,titles,category,lastkeyids) => {
            if(pageNumber === 1){
                const lastkeyids = 'empty';
                const seachlistallqqq = await Axios.get(`seachlistallsss/${pageNumber}/${type}/${locations}/${titles}/${category}/${lastkeyids}`);
                return seachlistallqqq.data;
            }else{ 
                const seachlistallqqq = await Axios.get(`seachlistallsss/${pageNumber}/${type}/${locations}/${titles}/${category}/${lastkeyids}`);
                return seachlistallqqq.data;
            }
        }
        seachlistallcan = async (pageNumber,type,names,experience,gender,lastkeyids) => {
            if(pageNumber === 1){
                const lastkeyids = 'empty';
                const seachlistallcanqqq = await Axios.get(`seachlistallcansss/${pageNumber}/${type}/${names}/${experience}/${gender}/${lastkeyids}`);
                return seachlistallcanqqq.data;
            }else{ 
                const seachlistallcanqqq = await Axios.get(`seachlistallcansss/${pageNumber}/${type}/${names}/${experience}/${gender}/${lastkeyids}`);
                return seachlistallcanqqq.data;
            }
        }
        AllFreelancerList = async (pageNumber,lastkeyids) => {
            const AllFreelancerListqqq= await Axios.get(`AllFreelancerListsss/${pageNumber}/${lastkeyids}`);
            return AllFreelancerListqqq.data;
        }
        AllauthorList = async (pageNumber,lastkeyids) => {
            const AllauthorListqqq= await Axios.get(`AllauthorListsss/${pageNumber}/${lastkeyids}`);
            return AllauthorListqqq.data;
        }
        Featuredsssallfl = async () => {
            const Featuredsssallflqqq = await Axios.get(`Featuredsssallflsss`);
            return Featuredsssallflqqq.data;
        }
        seachlistallhire = async (pageNumber,type,titles,category,lastkeyids) => {
            if(pageNumber === 1){
                const lastkeyids = 'empty';
                const seachlistallhireqqq = await Axios.get(`seachlistallhiresss/${pageNumber}/${type}/${titles}/${category}/${lastkeyids}`);
                return seachlistallhireqqq.data;
            }else{ 
                const seachlistallhireqqq = await Axios.get(`seachlistallhiresss/${pageNumber}/${type}/${titles}/${category}/${lastkeyids}`);
                return seachlistallhireqqq.data;
            }
        }
        seachlistallfl = async (pageNumber,type,ratesss,currency,lastkeyids) => {
            if(pageNumber === 1){
                const lastkeyids = 'empty';
                const seachlistallflqqq = await Axios.get(`seachlistallflsss/${pageNumber}/${type}/${ratesss}/${currency}/${lastkeyids}`);
                return seachlistallflqqq.data;
            }else{ 
                const seachlistallflqqq = await Axios.get(`seachlistallflsss/${pageNumber}/${type}/${ratesss}/${currency}/${lastkeyids}`);
                return seachlistallflqqq.data;
            }
        }
        seachlistallbooks = async (pageNumber,type,titles,catgorybooks,lastkeyids) => {
            if(pageNumber === 1){
                const lastkeyids = 'empty';
                const seachlistallbooks = await Axios.get(`seachlistallbooksss/${pageNumber}/${type}/${titles}/${catgorybooks}/${lastkeyids}`);
                return seachlistallbooks.data;
            }else{ 
                const seachlistallbooks = await Axios.get(`seachlistallbooksss/${pageNumber}/${type}/${titles}/${catgorybooks}/${lastkeyids}`);
                return seachlistallbooks.data;
            }
        }
        bookslistallauthor = async (ids) => {
            const bookslistallauthorqqq = await Axios.post('bookslistallauthorsss',{
                ids: ids,
            });
            return bookslistallauthorqqq.data;
        }
        noificasssall = async (ids) => {
            const noificasssallqqq = await Axios.post('noificasssallsss',{
                ids: ids,
            });
            return noificasssallqqq.data;
        }
        noificasssnavs = async (ids) => {
            const noificasssnavsqqq = await Axios.post('noificasssnavsss',{
                ids: ids,
            });
            return noificasssnavsqqq.data;
        }
        addnewsletters = async (userinfo) => {
            const addnewslettersqqq = await Axios.post('addnewsletterssss',{
                firstname:userinfo.firstname,
                lastname:userinfo.lastname,
                email:userinfo.email,
                myinbox:userinfo.myinbox,
            });
            return addnewslettersqqq.data;
        }
        hirecontactchecksall = async (ids) => {
            const hirecontactchecksallqqq = await Axios.post('hirecontactchecksallsss',{
                ids:ids,
            });
            return hirecontactchecksallqqq.data;
        }



       
        extendedshomesallDemo = async () => {
            const extendedshomesallqqq = await Axios.get('extendedshomesallsss')
            return extendedshomesallqqq.data;
        }



    render(){
        const contextValue = {
            
            rootState:this.state,
            isLoggedIn:this.isLoggedIn,
            isthemes:this.isthemes,
            logoutUser:this.logoutUser,
            isFeaturedIn:this.isFeaturedIn,
            RegisterUser:this.RegisterUser,
            LoginUser:this.LoginUser,
            emailverifyall:this.emailverifyall,
            ForgetMailpassword:this.ForgetMailpassword,
            ChangePasswordall:this.ChangePasswordall,
            jobsaddsssall:this.jobsaddsssall,
            profilelistall:this.profilelistall,
            viewallsssall:this.viewallsssall,
            viewjobsallsss:this.viewjobsallsss,
            jobseditsssall:this.jobseditsssall,
            publishjobssssall:this.publishjobssssall,
            deletesJobsall:this.deletesJobsall,
            deleteimagelistall:this.deleteimagelistall,
            memberstripe:this.memberstripe,
            memberstripeupgrage:this.memberstripeupgrage,
            extendedall:this.extendedall,
            extendedshomesallDemo:this.extendedshomesallDemo,
            Featuredsssall:this.Featuredsssall,
            logosssall:this.logosssall,
            categoriesall:this.categoriesall,
            Alljobsall:this.Alljobsall,
            RemoveLogoallimg:this.RemoveLogoallimg,
            RemoveResumeallfile:this.RemoveResumeallfile,
            viewcandidateall:this.viewcandidateall,
            EmployerBookMarkall:this.EmployerBookMarkall,
            EmployerBookMarkalljob:this.EmployerBookMarkalljob,
            shortlistall:this.shortlistall,
            deletejobshortlistall:this.deletejobshortlistall,
            viewshortlistjobsallsss:this.viewshortlistjobsallsss,
            deletecandidateshortlistall:this.deletecandidateshortlistall,
            ApplyCandidateall:this.ApplyCandidateall,
            Applysssall:this.Applysssall,
            MyAppliedall:this.MyAppliedall,
            deletemyappliedall:this.deletemyappliedall,
            MyApplicantsall:this.MyApplicantsall,
            deleteMyApplicantslistall:this.deleteMyApplicantslistall,
            Applysssallmeetings:this.Applysssallmeetings,
            candidatestripe:this.candidatestripe,
            AllEmployersList:this.AllEmployersList,
            AllCandidatesList:this.AllCandidatesList,
            hireviewallsssall:this.hireviewallsssall,
            HireJobsallqqq:this.HireJobsallqqq,
            deletehirejobimage:this.deletehirejobimage,
            deletefilesalllist:this.deletefilesalllist,
            deletesHireJobsall:this.deletesHireJobsall,
            deletefrrelancerimagelistall:this.deletefrrelancerimagelistall,
            Removefreelancerallimg:this.Removefreelancerallimg,
            Allhirejobsall:this.Allhirejobsall,
            ApplyFreelancersall:this.ApplyFreelancersall,
            ApplysssallHire:this.ApplysssallHire,
            Allapplyonesall:this.Allapplyonesall,
            ApplysssallHireContact:this.ApplysssallHireContact,
            ApplysssallusersContact:this.ApplysssallusersContact,
            InviteApplyallinvite:this.InviteApplyallinvite,
            CancelApplyallinvite:this.CancelApplyallinvite,
            hireviewallempall:this.hireviewallempall,
            viewnotificationall:this.viewnotificationall,
            Acceptjobsallaccept:this.Acceptjobsallaccept,
            CancelAcceptjobsallcancel:this.CancelAcceptjobsallcancel,
            freelancerstripe:this.freelancerstripe,
            RatingAds:this.RatingAds,
            memberstripebooks:this.memberstripebooks,
            bookslistall:this.bookslistall,
            booksnotificationall:this.booksnotificationall,
            Booksalllist:this.Booksalllist,
            switchall:this.switchall,
            isswitchIn:this.isswitchIn,
            isnamesIn:this.isnamesIn,
            updateprofilesall:this.updateprofilesall,
            updateprofilesallemp:this.updateprofilesallemp,
            deleteimagelistallemp:this.deleteimagelistallemp,
            Featuredsssallcan:this.Featuredsssallcan,
            updateprofilesallcan:this.updateprofilesallcan,
            seachlistall:this.seachlistall,
            seachlistallcan:this.seachlistallcan,
            AllFreelancerList:this.AllFreelancerList,
            updateprofilesallFL:this.updateprofilesallFL,
            Featuredsssallfl:this.Featuredsssallfl,
            seachlistallhire:this.seachlistallhire,
            seachlistallfl:this.seachlistallfl,
            seachlistallbooks:this.seachlistallbooks,
            AllauthorList:this.AllauthorList,
            bookslistallauthor:this.bookslistallauthor,
            isnotsssIn:this.isnotsssIn,
            noificasssall:this.noificasssall,
            noificasssnavs:this.noificasssnavs,
            addnewsletters:this.addnewsletters,
            memberstripeupgrade:this.memberstripeupgrade,
            memberstripehire:this.memberstripehire,
            hirecontactchecksall:this.hirecontactchecksall,
            

           
        }
        return(
            <MyContext.Provider value={contextValue}>
                {this.props.children}
            </MyContext.Provider>
        )
    }

}

export default MyContextProvider;